import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ScreenType } from '../../shared/enums/screen-type.enum';
import { StorageService } from '../storage/storage.service';
import { UserI } from '../../shared/interfaces/entities/user.interface';
import { DecodedTokenI } from '../../shared/interfaces/general/decoded-token.interface';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private user: UserI | null = null;

  private readonly isSideMenuOpen: Subject<boolean> = new Subject();

  private readonly screenType: Subject<ScreenType> = new Subject();

  constructor(private readonly storageService: StorageService) {}

  addIsSideMenuOpen(isSideMenuOpen: boolean) {
    this.isSideMenuOpen.next(isSideMenuOpen);
  }

  getIsSideMenuOpen$() {
    return this.isSideMenuOpen.asObservable();
  }

  addScreenType(screenType: ScreenType) {
    this.screenType.next(screenType);
  }

  getScreenType$() {
    return this.screenType.asObservable();
  }

  getDecodedToken(): DecodedTokenI {
    return JSON.parse(atob(this.storageService.accessToken?.split('.')[1]!));
  }

  getUser() {
    return this.user;
  }

  setUser(user: UserI | null) {
    this.user = user;
  }
}
